import React, { useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Badge, Descriptions } from "antd";
export const DescriptiveTable = ({
  data,
  TransformData,
  TransformValues,
  themeColor,
}) => {




  const ThemeColor = useSelector((state) => state?.common?.Theme);

useEffect(() => {
  var tableHeader = document.getElementsByClassName("ant-table-content")[0];
  var tableContent = document.getElementsByClassName("ant-table-tbody")[0];
  if (tableHeader && tableContent) {
    if (ThemeColor) {
      tableHeader.classList.remove("ant-descriptions-bordered");
      // tableContent.classList.remove("table_rows_black");
   
    } else {
      tableHeader.classList.add("ant-descriptions-bordered");
      // tableContent.classList.add("table_rows_black");


    }
  }
}, [ThemeColor]);




  const items = [
    {
      key: "1",
      label: "Activity Name",
      children: data?.form_name,
    },
    {
      key: "2",
      label: "Activity Type",
      children: TransformData[data?.activityTypeId],
    },
    {
      key: "3",
      label: "Organization",
      children: TransformValues("Organization"),
    },
    {
      key: "4",
      label: "Form Description",
      span: 3,
      children: data?.activity_description,
    },
    {
      key: "5",
      label: "Category",
      children: data?.category_name,
    },
    {
      key: "6",
      label: "Sub Category",
      children: data?.sub_category_name,
    },
    {
      key: "7",
      label: "Product",
      children: data?.product_name,
    },
    {
      key: "8",
      label: "Status",
      span: 3,
      children: <Badge status="processing" text={data?.status} />,
    },
    {
      key: "7",
      label: "Start Date",
      children: moment(data?.form_start_date).format("DD-MM-YY"),
    },
    {
      key: "8",
      label: "End Date",
      children: moment(data?.form_end_date).format("DD-MM-YY"),
    },
    {
      key: "9",
      label: "Field Staff Group",
      // children: TransformValues("name"),
      children: data?.name,
    },
  ];


  

  return (
    <>
      <p
        className={` text-xl pb-3 ${ThemeColor ? "text-black" : "text-white"}`}
      >
        View Form
      </p>
      <Descriptions
        layout="vertical" //bordered
        bordered={ThemeColor}
        items={items}
        className={`${ThemeColor ? " " : "bordered-descriptions-label "} 
        
         ${ThemeColor ? "" : "bordered-descriptions-content"}`}
        // className={ThemeColor ? "" : "bordered-descriptions-content"}
      />
    </>
  );
};
