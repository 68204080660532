import React from "react";

export const UserManagemntCard = ({
  title,
  Count,
  className,
  icon,
  ThemeColor,
  lastCardborder=false
}) => {
  return (
    <div
      className={`${
        ThemeColor
        ? `bg-white border-t border-b border-l ${lastCardborder ? "border-r" :""} border-[#E6E6E6]`
        : `dashboard_card_bg_color border-t-1 border-b-1 border-l-1 ${lastCardborder ? "border-r-1" :""} border-[#1E293B]`
    } w-1/6 py-8 px-4 card_p z-0 ${className}`}
    >
      <div className="dashboard_card_inner_right text-color">
      <div className={`text-xl font-light paragraphs_font ${ThemeColor ? "text-[#696C7F]" : "text-white/45"}`}>{title}</div>
        <div
          className={`${
            ThemeColor ? " text-[#121319] " : " text-white "
          } flex items-center text-[40px] font-normal paragraphs_font`}
        >
          {Count}
          {icon && (
            <div className="dashboard_card_left w-1/6 pl-2 mb-1">
              <img src={icon} alt="icon" className="w-5" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
