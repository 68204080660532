import React, { useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "components";
import { motion } from "framer-motion";
import { Select, SelectItem } from "@nextui-org/react";

export const SelectBox = ({
  className = "form-select",
  value = "",
  name = "",
  id = "",
  label = "",
  disabled = false,
  changeHandler,
  options = [],
  required = false,
  hidden,
  errorMessage = "",
  labelClass,
  ThemeColor,
  activity,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
    <div className={`relative select_field_container`}>
      <div className={`rounded w-full ${isFocused ? 'field-focused': 'field-label'}`}>
        <Select 
          id={id}
          name={name}
          label={label} 
          isRequired={required} 
          selectedKeys={[value?.toString()]}
          isDisabled={disabled}
          selectionMode="single"
          onKeyDown={() => setIsFocused(true)}
          onFocus={() => setIsFocused(true)} 
          onChange={changeHandler}
          onBlur={() => setIsFocused(false)}
          >
          {options.map((item) => (
            <SelectItem key={item.value || item.id} value={item.value || item.id}>{item.name || item.label}</SelectItem>
          ))}
        </Select>
      </div>
    </div>
       {errorMessage && (
        <span>
          <ErrorMessage message={errorMessage} />
        </span>
      )}
    </>
  );
};

SelectBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  changeHandler: PropTypes.func,
  options: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
};

// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import { ErrorMessage } from "components";
// import { motion } from "framer-motion";
// import { Select, SelectItem } from "@nextui-org/react";

// export const SelectBox = ({
//   className = "form-select",
//   value = "",
//   name = "",
//   id = "",
//   label = "",
//   disabled = false,
//   changeHandler,
//   options = [],
//   required = false,
//   hidden,
//   errorMessage = "",
//   labelClass,
//   ThemeColor,
//   activity,
//   placeholder = "Select",
// }) => {
//   const [isFocused, setIsFocused] = useState(false);

//   return (
//     <div className="relative select_field">
//       {/* Animated Motion Label */}
//       <motion.label
//         initial={{ y: 0, scale: 1 }}
//         animate={{
//           y: isFocused || value ? -20 : 0,
//           scale: isFocused || value ? 0.75 : 1,
//         }}
//         transition={{ duration: 0.3 }}
//         className={`absolute left-2 transition-all ${labelClass} paragraphs_font text-sm field-label`}
//         style={{ pointerEvents: "none" }}
//       >
//         {placeholder}
//         {required && <span className="text-red-500 ml-1">*</span>}
//       </motion.label>

//       {/* NextUI Select with custom styling */}
//       <Select
//         label={isFocused || value ? "" : placeholder}
//         value={value}
//         name={name}
//         id={id}
//         onFocus={() => setIsFocused(true)}
//         onBlur={() => !value && setIsFocused(false)}
//         onChange={changeHandler}
//         required={required}
//         disabled={disabled}
//         // placeholder="Select an option"
//         className={`max-w-xs ${className}`}
//       >
//         {options.map((item, index) => (
//           <SelectItem key={index} value={item.value || item.id}>
//             {item.name || item.label}
//           </SelectItem>
//         ))}
//       </Select>

//       {/* Error Message */}
//       {errorMessage && (
//         <span>
//           <ErrorMessage message={errorMessage} />
//         </span>
//       )}
//     </div>
//   );
// };

// SelectBox.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   name: PropTypes.string,
//   id: PropTypes.string,
//   className: PropTypes.string,
//   disabled: PropTypes.bool,
//   label: PropTypes.string,
//   required: PropTypes.bool,
//   changeHandler: PropTypes.func,
//   options: PropTypes.array.isRequired,
//   errorMessage: PropTypes.string,
// };
