import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "react-toastify";
import { setCookie } from "services/session/cookies";
import { parseZonedDateTime } from "@internationalized/date";

export const errorMessages = (message) => {
  if (typeof message === "string") {
    return message;
  } else {
    let list = "";
    for (var i = 0; i < message?.length; i++)
      list += ` ${i + 1}- ${message[i]} `;
    return list;
  }
};

export const successMessage = (message) => {
  return toast.success(message);
};

export const logOut = () => {
  setCookie("user", "");
  setCookie("accessToken", "");
};

export const mapValues = (isEdit, data, currentRecord, dateType) => {
  const fields = cloneDeep(data);
  let editData = cloneDeep(currentRecord);
  if (isEdit && fields.length && editData && Object.keys(editData).length) {
    fields.map((item) => {
      const fieldName = item["xmlName"];
      if (item["fieldType"] === "select") {
        item["value"] = editData[fieldName];
      } else if (item["fieldType"] === "date") {
        if (dateType && dateType === "momentType") {
          item["value"] = editData[fieldName]
            ? moment(editData[fieldName], "DD-MM-YYYY").toDate()
            : "";
        } else {
          item["value"] = editData[fieldName]
            ? new Date(editData[fieldName])
            : "";
        }
      } else {
        item["value"] =
          editData[fieldName] && editData[fieldName] != "null"
            ? editData[fieldName]
            : "";
      }
    });
  }
  return fields;
};

export const mapAllValues = (isEdit, state, data) => {
  console.log("isEdit, state, data", isEdit, state, data);
};

export const registerRegex = {
  userName: "^[a-zA-Z ]+$",
  email: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
  mobileNumber: /^[0-9]{4}-[0-9]{7}$/,
  // phoneNumber: /^\+?[0-9\s\-\(\)]{7,13}$/,
  password: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
  confirmPassword:
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
  name: "^[a-zA-Z ]+$",
  firstName: "^[a-zA-Z ]+$",
  lastName: "^[a-zA-Z ]+$",
  longName: "^[a-zA-Z ]+$",
  // website: "(https?:)?([w-])+.{1}([a-zA-Z]{2,63})([/w-]*)",
  // domain: "(https?:)?([w-])+.{1}([a-zA-Z]{2,63})([/w-]*)",
  ntnNumber: /^[0-9]{7}-[0-9]{1}$/,
  // address: "^\\W*(\\w+(\\W+|$)){1,500}$",
  // cnic: /^[0-9-]{15,20}$/,
  idenityId: /^[0-9-]{15,20}$/,
  imei: /^\d{15}(,\d{15})*$/,
};

export const registerErrorMessageObj = {
  userName: "Please enter valid name",
  firstName: "Please enter valid name",
  lastName: "Please enter valid name",
  email: "Please enter valid email e.g@.com",
  mobileNumber: "Please enter valid mobile number",
  // phoneNumber: "Please enter valid phone number",
  password:
    "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:",
  confirmPassword:
    "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:",
  name: "Please enter valid name",
  longName: "Please enter valid long name  ",
  // cnic: "Please enter valid cnic including - or Number",
  idenityId: "Please enter valid cnic including - or Number",
  imei: "Please enter valid IMEI 8XXXXXXXXXXXXXX",
  ntnNumber: "Please enter valid NTN Number xxxxxxx-x",
};

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const queryParamsCreator = (params) => {
  let queryString = "";

  if (params && Object.keys(params).length) {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => {
      const value = params[key];

      if (value) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            value.forEach((item) => {
              searchParams.append(key, item);
            });
          }
        } else {
          searchParams.append(key, value);
        }
      }
    });

    queryString = searchParams.toString();
  }

  return queryString ? `?${queryString}` : "";
};


/**
 * Checks if a given time is within the last 15 minutes relative to the current time.
 * @param {string | Date} time - The time to check. Can be a valid date string or a Date object.
 * @returns {boolean} True if the given time is within the last 15 minutes, false otherwise.
 */
export const isTimeWithinLast15Minutes = (time) => {
  // Given time
  const givenTime = new Date(time);

  // Current time
  const currentTime = new Date();

  // Calculate 15 minutes ago
  const fifteenMinutesAgo = new Date(currentTime.getTime() - 15 * 60 * 1000);

  // Check if givenTime is within the 15-minute window
  return (givenTime >= fifteenMinutesAgo && givenTime <= currentTime);
};


export const getDateRange = (startDate, endDate) => {
  // Get user's local time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get user's local time in HH:MM format
  const today = new Date();
  const hours = String(today.getHours()).padStart(2, '0'); // Format hours
  const minutes = String(today.getMinutes()).padStart(2, '0'); // Format minutes
  const localTime = `${hours}:${minutes}`;

  // Format dates
  let minVALUE = `20${moment(startDate).format("YY-MM-DD")}`;
  let maxValue = `20${moment(endDate).format("YY-MM-DD")}`;

  // Create min and max date strings
  const minDateString = `${minVALUE}T${localTime}[${userTimeZone}]`;
  const maxDateString = `${maxValue}T${localTime}[${userTimeZone}]`;

  // Parse dates
  const minDate = parseZonedDateTime(minDateString);
  const maxDate = parseZonedDateTime(maxDateString);

  return { minDate, maxDate };
};



export const messsageStatusResponse = (myJson) => {
  if (myJson?.response) {
    const message = myJson?.response?.data?.message ?? "Something went wrong";
    const statusCode = myJson?.response?.data?.statusCode ?? 500;
    return { message, statusCode };
  } else {
    const message = myJson?.data?.message ?? "Something went wrong";
    const statusCode = myJson?.data?.statusCode ?? 500;
    return { message, statusCode };

  }
}