import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import home from "assets/home.png";
import group from "assets/group.svg";
import profit from "assets/profit.svg";
import sun from "assets/sun.svg";
import moon from "assets/moon.svg";
import geofence from "assets/geofence.svg";
import svg_logo from "../../../assets/svg_logo.svg";
import geo_fence_color from "assets/geo_fence_color.svg";
import feature from "assets/feature-off.svg";
import feature_color from "assets/feature.svg";
import grid from "assets/grid.svg";
import eye_menu from "assets/eye_menu.svg";
import eye_color from "assets/eye_color.svg";
import profit_color from "assets/profit_color.png";
import group_clr from "assets/group_clr.svg";
import pop_management from "assets/pop_management.svg";
import pop_colored from "assets/pop_colored.svg";
import organization_dark from "assets/organization_dark.svg";
import organization_color from "assets/organization_color.svg";
import Speech_dark from "assets/Speech_dark.svg";
import Speech_color from "assets/Speech_color.svg";
import Reporting from "assets/Reporting.svg";
import reporting_color from "assets/reporting_color.svg";
import Question from "assets/Question.svg";
import game_color from "assets/game_color.svg";
import game_dark from "assets/game_dark.svg";
import announcement_color from "assets/announcement-active.svg";
import announcement_dark from "assets/announcement.svg";
import { MainMenu } from "utils/constants/SibarBarMenu";
import { setCookie, getCookie } from "services/session/cookies";
import { ChangeTheme } from "services/api/common";
import { GenericTooltip } from "components";
import { useTranslation } from "react-i18next";
import { DropDownFiltration, resetFilters } from "store/actions/Filters";

const Sidebar = ({
  ThemeColor,
  setSideNavigationShow,
  sideNavigationShow,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isShown, setIsShown] = useState();
  const permissions = getCookie("permissions" ? "permissions" : []);
  const [t, i18n] = useTranslation('global')
  const changeThemeColor = (value) => {
    dispatch(ChangeTheme(value));
  };

  const toggleThemeColor = (isLightMode) => {
    if (isLightMode === true) {
      localStorage.setItem("ThemeColor", "light");
      changeThemeColor(isLightMode);
    } else {
      localStorage.setItem("ThemeColor", "dark");
      changeThemeColor(isLightMode);
    }
  };

  const NavItem = () => {
    if (location.pathname === "/user-management") {
      return setIsShown(3);
    } else if (location.pathname === "/program-management") {
      return setIsShown(2);
    } else if (location.pathname === "/organization-management") {
      return setIsShown(4);
    } else if (location.pathname === "/pop-type-management") {
      return setIsShown(5);
    } else if (location.pathname === "/reporting") {
      return setIsShown(7);
    } else if (location.pathname === "/geo-fence") {
      return setIsShown(6);
    } else if (location.pathname === "/games") {
      return setIsShown(10);
    } else if (location.pathname === "/feature-management") {
      return setIsShown(11);
    } else if (location.pathname === "/announcement") {
      return setIsShown(12);
    } else if (location.pathname === "/speech-checking") {
      return setIsShown(8);
    } else if (location.pathname === "/monitoring") {
      return setIsShown(9);
    }
  };

  useEffect(() => {
    NavItem();
  }, []);

  const performBeforeNavigation = async (destination) => {
    try {
      // Dispatch actions and wait for their completion
      await dispatch(resetFilters());
      await dispatch(
        DropDownFiltration({
          organization: "",
          program: "",
          activity: "",
          form: "",
          fieldStaff: "",
          fieldStaffGroup: "",
          geoFence: "",
          site: "",
          branch: "",
          filterby: "default",
          filterMode: "multiple",
        })
      );

      // Navigate after actions are completed
      navigate(destination);
    } catch (error) {
      console.error('Error performing actions before navigation:', error);
      // Handle error if necessary
    }
  };

  const handleEvent = (key, destination) => {
    const selectedItem = MainMenu.find((item) => item.key === key);
    if (selectedItem) {
      const { label, link, items } = selectedItem;
      const mappedItems = items
        ? items.map((item) => {
            return {
              label: item.label,
              value: item.link,
              permission: item.permission,
            };
          })
        : [];
      let result = [{ label, value: link }];
      if (mappedItems.length > 0 && permissions.length > 0) {
        const checkPermissions = mappedItems.filter((item) => {
          return JSON.parse(permissions).includes(item.permission);
        });
        result = result.concat(checkPermissions);
      } else {
        result = result.concat(mappedItems);
      }
      setCookie("navItems", JSON.stringify(result));
    }
    performBeforeNavigation(destination);
  };

  const pathChange = () => {
    const selectedItem = MainMenu.find((item) => item.link === location.pathname);
    if (selectedItem) {
      const { label, link, items } = selectedItem;
      const mappedItems = items
        ? items.map((item) => {
            return {
              label: item.label,
              value: item.link,
              permission: item.permission,
            };
          })
        : [];
      let result = [{ label, value: link }];
      if (mappedItems.length > 0 && permissions.length > 0) {
        const checkPermissions = mappedItems.filter((item) => {
          return JSON.parse(permissions).includes(item.permission);
        });
        result = result.concat(checkPermissions);
      } else {
        result = result.concat(mappedItems);
      }
      setCookie("navItems", JSON.stringify(result));
    }
  };

  useEffect(() => {
    if(location.pathname){
      pathChange()
    }
  }, [location.pathname])

  const activeItem = (showKey) => {
    setIsShown(Number(showKey));
  };

  return (
    <>
      <div
        className={`text-white h-dvh w-siderbar fixed top-0 bottom-0 pt-2 flex flex-col items-center justify-between z-30 bg-black`}
      >
        <div className="flex flex-col space-y-1 items-center justify-between">
          <div className="pb-5">
            <Link to="/main-dashboard">
              <img src={svg_logo} alt="svg_logo" />
            </Link>
          </div>
          {/* <div
            className="pt-3 cursor-pointer"
            onClick={() => {
              setSideNavigationShow(!sideNavigationShow);
            }}
          >

            <img src={grid} alt="svg_logo" />
          </div> */}
          <div className="pt-3" onClick={() => handleEvent("A01", "/main-dashboard")}>
            <GenericTooltip
              placement="right"
              title={t('sidebar.home')}
              content="content"
              className="bg-white text-black"
              arrow={{ arrowPointAtCenter: true }}
            >
              <Link to="/main-dashboard">
                <img
                  src={isShown === 1 ? home : grid}
                  alt="home_icon"
                  className="cursor-pointer"
                />
              </Link>
            </GenericTooltip>
          </div>
          {JSON.parse(permissions).includes("AM_PGMS_54") ? (
            <div
              className="pt-6"
              onClick={() => handleEvent("B02", "/program-management")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.programManagement")}
                content="content"
                className="bg-white text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/program-management">
                  <img
                    src={isShown === 2 ? profit_color : profit}
                    alt="activity_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem("2");
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}

          {JSON.parse(permissions).includes("UM_1") ? (
            <div
              className="pt-6"
              onClick={() => handleEvent("C03", "/user-management")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.userManagement")}
                content="content"
                className="bg-white text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/user-management">
                  <img
                    src={isShown === 3 ? group_clr : group}
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem("3");
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {JSON.parse(permissions).includes("OM_18") ? (
            <div
              className="pt-6"
              onClick={() => handleEvent("D04", "/organization-management")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.organizationManagement")}
                content="content"
                className="bg-white text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/organization-management">
                  <img
                    src={isShown === 4 ? organization_color : organization_dark}
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem("4");
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}

          {JSON.parse(permissions).includes("PM_37") ? (
            <div
              className="pt-6"
              onClick={() => handleEvent("E05", "/pop-type-management")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.POPManagement")}
                content="content"
                className="bg-white text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/pop-type-management">
                  <img
                    src={isShown === 5 ? pop_colored : pop_management}
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem("5");
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {JSON.parse(permissions).includes("RM_66") ? (
            <div
              className="pt-6"
              onClick={() => handleEvent("G01", "/reporting")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.reporting")}
                content="content"
                className="bg-white text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/reporting">
                  <img
                    src={isShown === 7 ? reporting_color : Reporting}
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem("7");
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {JSON.parse(permissions).includes("MM_69") ? (
            <div
              className="pt-6"
              onClick={() => handleEvent("H01", "/monitoring")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.monitoring")}
                content="content"
                className="bg-white text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/monitoring">
                  <img
                    src={isShown === 9 ? eye_color : eye_menu}
                    sv
                    alt="home_icon"
                    className="cursor-pointer bg-color[#6F767E]"
                    onClick={() => {
                      activeItem("9");
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {JSON.parse(permissions).includes("GM_62") ? (
            <div
              className="pt-6"
              onClick={() => handleEvent("F01", "/geo-fence")}
            >
              <GenericTooltip
                placement="right"
                title={t("sidebar.geoFencing")}
                content="content"
                className="bg-white text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/geo-fence">
                  <img
                    src={isShown === 6 ? geo_fence_color : geofence}
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem("6");
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          ) : (
            ""
          )}
          {/* {JSON.parse(permissions).includes("FM_72") ? ( */}
            <div className="pt-6" onClick={() => handleEvent("F02", "/feature-management")}>
              <GenericTooltip
                placement="right"
                title={t('sidebar.featureManagement')}
                content="content"
                className="bg-white text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/feature-management">
                  <img
                    src={isShown === 11 ? feature_color : feature}
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem("11");
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
          {/* ) : (
            ""
          )} */}
          {
            JSON.parse(permissions).includes('GGM_82') ? (
              <div className="pt-6" onClick={() => handleEvent("I01", "/games")}>
              <GenericTooltip
                placement="right"
                title={t("sidebar.gamesManagement")}
                content="content"
                className="bg-white text-black"
                arrow={{ arrowPointAtCenter: true }}
              >
                <Link to="/games">
                  <img
                    src={isShown === 10 ? game_color : game_dark}
                    alt="home_icon"
                    className="cursor-pointer"
                    onClick={() => {
                      activeItem("10");
                    }}
                  />
                </Link>
              </GenericTooltip>
            </div>
            ):(
              ""
            )
          }
          <div className="pt-6" onClick={() => handleEvent("J01", "/announcement")}>
            <GenericTooltip
              placement="right"
              title={t("sidebar.announcement")}
              content="content"
              className="bg-white text-black"
              arrow={{ arrowPointAtCenter: true }}
            >
              <Link to="/announcement">
                <img
                  src={isShown === 12 ? announcement_color : announcement_dark}
                  alt="home_icon"
                  className="cursor-pointer"
                  onClick={() => {
                    activeItem("12");
                  }}
                />
              </Link>
            </GenericTooltip>
          </div>

          <div
            className="pt-6"
            onClick={() => handleEvent("H01", "/speech-checking")}
          >
            <GenericTooltip
              placement="right"
              title={t("sidebar.speechToText")}
              content="content"
              className="bg-white text-black"
              arrow={{ arrowPointAtCenter: true }}
            >
              <Link to="/speech-checking">
                <img
                  src={isShown === 8 ? Speech_color : Speech_dark}
                  alt="home_icon"
                  className="cursor-pointer"
                  onClick={() => {
                    activeItem("8");
                  }}
                />
              </Link>
            </GenericTooltip>
          </div>
        </div>

        <div
          className="pb-4 flex items-center flex-col "
          onClick={() => setIsShown(7)}
        >
          <div className="pb-4 flex items-center">
            <img
              src={Question}
              alt="Settings_logo"
              className="cursor-pointer"
            />
          </div>
          <div>
            <GenericTooltip
              placement="right"
              title={t("sidebar.changeTheme")}
              content="content"
              ClassName="bg-white text-black"
              arrow={{ arrowPointAtCenter: true }}
            >
              {ThemeColor ? (
                <img
                  src={moon}
                  alt="Settings_logo"
                  className="cursor-pointer"
                  onClick={() => toggleThemeColor(false)}
                />
              ) : (
                <img
                  src={sun}
                  alt="Settings_logo"
                  className="cursor-pointer"
                  onClick={() => toggleThemeColor(true)}
                />
              )}
            </GenericTooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
