import React, { useEffect } from "react";

export const PopModal = ({
  modalOpen,
  setModalOpen,
  title,
  Children,
  width,
  setIsEdit,
  isCustom,
  icon,
  titleContent,
}) => {
  // Effect to toggle overflow on both html and body when modal is open/closed
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflowY = 'hidden';
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
      document.documentElement.style.overflowY = 'auto';
    }

    // Clean up the effect on unmount or modal close
    return () => {
      document.body.style.overflowY = 'auto';
      document.documentElement.style.overflowY = 'auto';
    };
  }, [modalOpen]);

  return (
    <>
      {modalOpen && <div
        className={`modal-container-overlay fixed h-full top-0 left-0 right-0 bottom-0 ${modalOpen ? "flex justify-center items-center" : "hide"}`}
      >
        <div
          className="absolute top-0 bottom-0 left-0 right-0 w-full z-40"
          onClick={() => {
            setModalOpen(false);
            if (setIsEdit != undefined) {
              setIsEdit(false);
            }
          }}
        ></div>
        <div className={`flex flex-col bg-dark-theme ${width} z-50 rounded-2xl`}>
          <div className="flex pt-8 pb-8 lg:pb-6 lg:pt-6 modal-title-container pl-8 pr-8 rounded-t-lg">
            {icon ? (
              <div className="icon-container flex items-center justify-center">
                <img src={icon} alt="icon" />
              </div>
            ) : null}
            <div className="ml-4">
              <div className="text-white text-xl">{title}</div>
              <div className="text-base text-white mt-1 title-content">
                {titleContent}
              </div>
            </div>
          </div>
          <div className={`${isCustom ? '' : 'px-10 py-5'}`}>{Children}</div>
        </div>
      </div>}
    </>
  );
};
