import logo from "../../assets/images/intelytics_logo.svg";
import intText from "../../assets/images/intelytics_text.svg";

export const LeftPannel = ({
  createNewAccount,
  headingtext,
  coloredText,
  paragraph,
  lowerText,
  linkText
}) => {
  return (
    <div className={`${lowerText ? "login_left_panel_initial" : "login_left_panel_initial"} Login_left_pannel h-full`}>
      <div className="login_left_panel_internal_box">
        <div className="logo_and_text">
          <img src={logo} alt="logo" />
          <div className="text hidden lg:block">
            <img src={intText} alt="intText" />
          </div>
        </div>
        <div className="content_below_logo">
          {createNewAccount ? (
            <div className="flex items-center content_headingText">
              <h1 className="pr-2">{headingtext}</h1>
              <h2>{coloredText}</h2>
            </div>
          ) : (
            <div className="content_headingText">
              <h1>Beyond trends navigating the</h1>
              <h2>data driven revolution</h2>
            </div>
          )}
          <p className="content_supporting_text">
            {paragraph
              ? paragraph
              : "Harnessing the power of data-driven insights to predict trends and optimize strategies."}
          </p>
          <div className="flex flex-col gap-6 sm:flex-row lg:flex-col xl:flex-row content-between items-center">
            <div className="card_login max-w-xs sm:w-3/6">
              <p className="card_header">BI & Analytics</p>
              <p className="card_text">
                Use intuitive dashboards, reporting, and Al-generated insights
                to see what's going on in your business and make the next right
                move.
              </p>
              <button>
                See BI & Analytics
              </button>
            </div>
            <div className="card_right_section w-full sm:w-3/6">
              <div className="mb-4">
                <h1>The Spreadsheet</h1>
                <p className="paragraphs_font">
                  an interface you already know
                </p>
              </div>
              <div className="mb-4">
                <h1>Powerful Operations</h1>
                <p className="paragraphs_font">
                  on billions of rows in seconds
                </p>
              </div>
              <div>
                <h1>Collaborate</h1>
                <p className="paragraphs_font">
                  with your team on telling your data story
                </p>
              </div>
            </div>
          </div>
          <div className="bottom_section paragraphs_font">{lowerText ? lowerText : "By using our services, you agree to abide by our "} <span className="link-text">{linkText ? linkText : "Terms & Conditions"}</span></div>
        </div>
      </div>
    </div>
  );
};
