import React from "react";
import { Breadcrumb } from "antd";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { breadCrumbsJson as Data } from "utils/constants/SibarBarMenu";

export const BreadcrumbComponent = ({ separator = ">" }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const createNestedLinks = ({ text, Links }) => {
    const parts = text.split(" > ");
    const links = Links.split("/").filter(Boolean);

    return parts.map((part, index) => {
      const path = `/${links[index]}`;
      const isLast = index === parts.length - 1;
      const isSecondLast = index === parts.length - 2;

      return (
        <span key={index}>
          {isLast ? (
            <span className="text-bold ">{part}</span>
          ) : isSecondLast ? (
            <span
              className="hover:text-blue-500 text-[#6E7C87] cursor-pointer"
              onClick={() => navigate(-1)}
            >
              {part}
            </span>
          ) : (
            <Link to={path} className="hover:text-blue-500 text-[#6E7C87]">
              {part}
            </Link>
          )}
          {index < parts.length - 1 && " > "}
        </span>
      );
    });
  };

  const ThemeColor = useSelector((state) => state?.common?.Theme);
  return (
    <>
      <div
        className={`pt-2 pl-14 pb-2 text-sm flex items-center ${
          ThemeColor
            ? "text-[#252C32] bg-[#FFFFFF] bread-board "
            : "dashboard_bread_bg_color border"
        }`}
      >
        {Data.map(
          (item, index) =>
            location.pathname.startsWith(item.path) && (
              <div key={index}>{createNestedLinks(item)}</div>
            )
        )}
      </div>
    </>
  );
};

BreadcrumbComponent.propTypes = {
  separator: PropTypes.string,
};
